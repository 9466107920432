html {
  background-color: rgb(239, 240, 241);
  --text-background: rgb(239, 240, 241);
}

body {
  color: rgb(45, 42, 38);
  margin: 0;
  padding: 0;
  font-family: Merriweather, Georgia, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'lnum' 1, 'pnum' 1, 'kern' 1, 'liga' 1, 'clig' 1;
          font-feature-settings: 'lnum' 1, 'pnum' 1, 'kern' 1, 'liga' 1, 'clig' 1;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
}

code {
  font-family: 'Dank Mono', 'JetBrains Mono', source-code-pro, Menlo, monospace;
}

a {
  color: rgb(54, 52, 50);
  text-decoration: none;
  background-repeat: repeat-x;
  outline-color: rgb(237, 95, 70);
  background-image: linear-gradient(
    to right,
    rgba(54, 52, 50, 0.84) 100%,
    rgba(54, 52, 50, 0) 0
  );
  background-image: linear-gradient(
    to right,
    currentColor 100%,
    currentColor 0
  );
  background-size: 1px 1px;
  background-position: 0 1.15em;
  background-position: 0 calc(1em + 3px);
  /* If vars aren't supported, don't bother trying to occlude. */
  text-shadow: 0 0 rgb(239, 240, 241), 1px 0 rgb(239, 240, 241),
    2px 0 rgb(239, 240, 241), 2.5px 0 rgb(239, 240, 241),
    -1px 0 rgb(239, 240, 241), -2px 0 rgb(239, 240, 241),
    -2.5px 0 rgb(239, 240, 241);
  text-shadow: 0 0 var(--text-background), 1px 0 var(--text-background),
    2px 0 var(--text-background), 2.5px 0 var(--text-background),
    -1px 0 var(--text-background), -2px 0 var(--text-background),
    -2.5px 0 var(--text-background);
}

/* The code font needs a narrower shadow that extends further down. */
code a,
a code {
  text-shadow: 0 1px rgb(239, 240, 241), 1px 1px rgb(239, 240, 241),
    1.5px 1px rgb(239, 240, 241), -1px 1px rgb(239, 240, 241),
    -1.5px 1px rgb(239, 240, 241);
  text-shadow: 0 1px var(--text-background), 1px 1px var(--text-background),
    1.5px 1px var(--text-background), -1px 1px var(--text-background),
    -1.5px 1px var(--text-background);
}

sub,
sup {
  line-height: 0;
}

@-webkit-keyframes loading {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1400px;
  }
}

@keyframes loading {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1400px;
  }
}

.spectacle-content img {
  max-width: 100%;
  height: auto;
}

.spectacle-deck {
  padding-top: 3rem;
}

.spectacle-deck p {
  margin-bottom: 0;
}

